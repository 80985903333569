import imgleft from '../../assets/images/profile/img-left.svg';
import imgright from '../../assets/images/profile/img-right.svg';

function PageHead() {
    return (
        <div>
        <div className="page-head">
        <div className="row align-items-center">
            <div className="col">
                <img src={imgleft} className="img-fluid" alt="" />
            </div>
            <div className="col">
                <div className="page-head-info">
                    <h2>Welcome, James Smith</h2>
                    <p>Learn With Effectively With Us!</p>
                </div>
            </div>
            <div className="col">
                <img src={imgright} className="img-fluid" alt="" />
            </div>
        </div>
    </div>
        </div>
    );
}

export default PageHead;