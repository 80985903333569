import HeaderAuth from "../../header/home/HeaderAuth";

import imgRegister from '../../assets/images/auth/registern-now.svg';
import icGoogle from '../../assets/images/auth/google.svg';
import { Link,Navigate,useNavigate} from 'react-router-dom';
import { Form } from 'react-bootstrap';
import React, { useState,useEffect } from 'react';
import axios from './../../axios';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import config from "./../../config";

function SignUp() {
    // let history = useHistory();    
    const isAuthenticated=!!localStorage.getItem('access_token');
    
    const [validated, setValidated] = useState(false);
    const [first_name_err, setFirstNameErr] = useState('');
    const [last_name_err, setLastNameErr] = useState('');
    const [email_err, setEmailErr] = useState('');
    const [term_err, setTermErr] = useState('');
    let navigate = useNavigate();
     const componentClicked = (response) => {
        console.log(response);

    }
    const responseFacebook = (response) => {
         const socialSignIn = {

                first_name : response.first_name,
                last_name : response.last_name,
                email : 'meh@mailinator.com',
                facebook_id : response.userID,
            };
         axios
            .post("/auth/facebook",socialSignIn)
            .then((res) => {
                console.log(res);
                if(res.status == 200){
                    localStorage.setItem('access_token', JSON.stringify(res.data.records.user.access_token))
                
                    navigate('/');
                    
                }
            })
            .catch((err) => {
                //error
            });
    }
    const responseGoogle = (response) => {

        console.log(response.profileObj);
        const socialGoogleSignIn = {

                first_name : response.profileObj.givenName,
                last_name : response.profileObj.familyName,
                email : response.profileObj.email,
                google_id : response.profileObj.googleId,
            };
         axios
            .post("/auth/gmail",socialGoogleSignIn)
            .then((res) => {
                console.log(res);
                if(res.status == 200){
                    localStorage.setItem('access_token', JSON.stringify(res.data.records.user.access_token))
                    navigate('/');

                    
                }
            })
            .catch((err) => {
                //error
            });
    }
    useEffect(() => {

        if(isAuthenticated){
            navigate('/');
            console.log('Logged In');
        }
        else{
            console.log('Not Logged In');
        }
        

    });

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            console.log(form.checkValidity());
        }
        else {
            const signUpData = {
                first_name : event.target.first_name.value,
                last_name : event.target.last_name.value,
                email : event.target.email.value,
                terms_and_conditions : event.target.term.value,
            };
            
            console.log('hello');
            axios
            .post("/auth/signup",signUpData)
            .then((res) => {
                console.log("errors=>",res.data.errors);
                console.log("student-signup == res = ", {res});
                
                if(res.data._metadata.outcomeCode != 200){
                    console.log('errors');
                    setValidated(false);

                    if(res.data.errors.first_name){
                        setFirstNameErr(res.data.errors.first_name);                    
                    }
                    if(res.data.errors.last_name){
                        setLastNameErr(res.data.errors.last_name);                    
                    } 
                    if(res.data.errors.email){
                        setEmailErr(res.data.errors.email);                    
                    }
                    if(res.data.errors.terms_and_conditions){
                        setTermErr(res.data.errors.terms_and_conditions);                    
                    }

                }
                else{
                    event.preventDefault();
                    event.stopPropagation();
                        
                        let message = res.data._metadata.message
                        
                        localStorage.setItem('message', JSON.stringify(message))
                    navigate('/welcome');
                       
                }
                
            })
            .catch((err) => {
                console.log({err});
            });
            
        }
        
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        
    };

    return (
        <div>
            <HeaderAuth />
            <section className="aut-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 hide-on-mobile">
                            <img src={imgRegister} className="img-fluid" alt="" />
                        </div>
                        <div className="col-md-5 offset-md-2">
                            <div className="card auth-card">
                                <div className="card-body">
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <h3 className="text-center mb-4">Register Now</h3>
                                    <div className="form-group mb-2 input-icon">
                                        <input name="first_name" minLength="3" type="text" className="form-control" placeholder="First Name"required />
                                        <div className="invalid-feedback">Please enter first name must be at least 3 characters.</div>
                                        <div className="danger">{first_name_err && first_name_err}</div>
                                        <span>
                                            <i className="ri-user-line"></i>
                                        </span>
                                    </div>
                                    <div className="form-group mb-2 input-icon">
                                        <input name="last_name" minLength="3" type="text" className="form-control" placeholder="Last Name" required />
                                        <div className="invalid-feedback">
                                        Please enter last name must be at least 3 characters.</div>
                                        <div className="error">
                                           {last_name_err && last_name_err}</div>
                                        <span>
                                            
                                            <i className="ri-user-line"></i>
                                        </span>
                                    </div>
                                    <div className="form-group mb-2 input-icon">
                                        <input name="email" type="email" className="form-control" pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+" placeholder="Email" required />
                                        <div className="invalid-feedback">
                                         Please enter a valid email address.</div>
                                         <div className="error">
                                           {email_err && email_err}</div>
                                            
                                        <span>
                                            <i className="ri-mail-line"></i>
                                        </span>
                                    </div>
                                    <div className="form-group mb-2">
                                        <div className="form-check text-center">
                                            <input className="form-check-input" type="checkbox" name="term" value="1"  id="flexCheckDefault" required/>
                                            
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                I have read and accepted with the <a href="#">Privacy Policy</a>
                                            </label>
                                            <div className="invalid-feedback">Terms & Condition field is required
                                            <div className="danger">
                                           {email_err && email_err}</div>
                                         </div>
                                        </div>
                                    </div>
                                    <div className="form-group mb-2">
                                        <button className="button button-primary button-round button-block">Register Now</button>
                                    </div>
                                    <div className="form-group mb-2">
                                        <div className="form-check text-center">
                                            <label className="form-check-label">
                                                Already have an account? <Link to="/signin">Return to Sigin</Link>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group mb-2">
                                        <FacebookLogin
                                            appId={config.facebook_app_id}
                                            
                                            fields="first_name,last_name,name,email,picture"
                                            cssClass="button button-fb button-icon button-round button-block"
                                            icon="fab fa-facebook-f"
                                            onClick={componentClicked}
                                            callback={responseFacebook} />
                                    </div>
                                    <div className="form-group mb-2">
                                        <GoogleLogin
                                            clientId={config.google_client_id}
                                            buttonText="Signup with Gmail"
                                            className="button button-outline-gray button-icon button-round button-block"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                          />
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SignUp;