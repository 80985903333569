import logo from '../../assets/images/logo.png';
function HeaderProfileProcess()
{
    return (
        <div>
            <header>
        <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="" />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                            <i class="ri-global-line"></i>
                                <span>Language</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                            <i class="ri-home-4-line"></i>
                                <span>Home</span>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">
                            <i class="ri-group-line"></i>
                                <span>FAQs</span>
                            </a>
                        </li>
                        <li className="nav-item border-left">
                            <a className="nav-link" href="#">
                            <i class="ri-notification-3-line"></i>
                                <span>Notifications</span>
                                <label>25</label>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
        </div>
    )
}

export default HeaderProfileProcess;