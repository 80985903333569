import { Link } from 'react-router-dom';
import imgWelcome from '../../assets/images/auth/register-welcome.svg';
import HeaderAuth from "../../header/home/HeaderAuth";
import React, { useEffect, useState } from "react";
import { useParams,useLocation } from 'react-router-dom';

class Welcome extends React.Component {
    state = {
        message : ''
    }

    componentDidMount(){
        let message = JSON.parse(localStorage.getItem('message'));
        const isAuthenticated=!!localStorage.getItem('access_token');
        if(isAuthenticated){
            console.log('Logged In');
        }
        else{
            console.log('Not Logged In');
        }
        this.setState({
            message : message
        });
        
    }

    render(){
            return(
        <div>
            <HeaderAuth />
            <section className="aut-wrap">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-7 hide-on-mobile">
                    <img src={imgWelcome} className="img-fluid" alt="" />
                </div>
                <div className="col-md-5">
                    <div className="card auth-card">
                        <div className="card-body">
                            <h2 className="text-center mb-2">Welcome Register</h2>
                           
                            <p className="text-center mb-5">{this.state.message}</p>
                           
                            <div className="form-group mb-3">
                                <Link to="/signin" className="button button-primary button-round button-block">Back to Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </div>
    );        
    }

}

export default Welcome;