const Config={

  //base_url: "http://localhost:8000/api",
  google_client_id : "536495824262-32dn5olvbjjthou3rssmsheuvl4eo07j.apps.googleusercontent.com",
  facebook_app_id : "2913718795440892",

  //base_url: "http://localhost:8000/api",


    base_url: "https://appla.20thfloor.com/api",


};
 export default Config