import HeaderAuth from "../../header/home/HeaderAuth";
import imgSignIn from '../../assets/images/auth/registern-now.svg';
import icGoogle from '../../assets/images/auth/google.svg';
import {useParams ,useLocation, Link , useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import React, { useState,useEffect } from 'react';
import axios from './../../axios';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import config from "./../../config";
function SignIn(props) {
    //const query = props.location.search;
    const isAuthenticated=!!localStorage.getItem('access_token');
    const [pass_err, setPassErr] = useState('');
    const [user_err, setUserErr] = useState('');
    const [verify_err, setVerifyErr] = useState('');
    const [validated, setValidated] = useState(false);
    const [verified, setVerified] = useState(false);
    const params = useParams()
    let navigate = useNavigate();
    
    const componentClicked = (response) => {
        console.log(response);

    }
    const responseFacebook = (response) => {
         const socialSignIn = {

                first_name : response.first_name,
                last_name : response.last_name,
                email : 'meh@mailinator.com',
                facebook_id : response.userID,
            };
         axios
            .post("/auth/facebook",socialSignIn)
            .then((res) => {
                console.log(res);
                if(res.status == 200){
                    localStorage.setItem('access_token', JSON.stringify(res.data.records.user.access_token))
                
                    navigate('/');
                    
                }
            })
            .catch((err) => {
                //error
            });
    }
    const responseGoogle = (response) => {

        console.log(response.profileObj);
        const socialGoogleSignIn = {

                first_name : response.profileObj.givenName,
                last_name : response.profileObj.familyName,
                email : response.profileObj.email,
                google_id : response.profileObj.googleId,
            };
         axios
            .post("/auth/gmail",socialGoogleSignIn)
            .then((res) => {
                console.log(res);
                if(res.status == 200){
                    localStorage.setItem('access_token', JSON.stringify(res.data.records.user.access_token))
                    navigate('/');

                    
                }
            })
            .catch((err) => {
                //error
            });
    }
    const useQuery= () => {
       return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();

    useEffect(() => {

        if(isAuthenticated){
            navigate('/');
            console.log('Logged In');
        }
        else{
            console.log('Not Logged In');
        }
    if(query.get('email-verified')){
        setVerified('Your Email is Already Verified');
    }

    });
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else{
            const signInData = {
                email : event.target.email.value,
                password : event.target.password.value,
            };

            axios
            .post("/auth/signin",signInData)
            .then((res) => {
                console.log(res);
                if(res.data._metadata.outcomeCode == 2){
                    setPassErr(res.data.errors.password);
                    if(res.data.errors){
                        setUserErr(res.data._metadata.message.message);
                    }else{
                        setPassErr(res.data._metadata.message.message);
                        setUserErr('');
                    }              
                }
                if(res.data._metadata.outcomeCode == 404){
                    setUserErr(res.data._metadata.message);
                    setPassErr('');    
                    setVerifyErr('');

                }
                if(res.data._metadata.outcomeCode == 3){
                    setVerifyErr(res.data._metadata.message);
                    setPassErr('');
                    setUserErr('');                    
                }
                if(res.data._metadata.outcomeCode == 200){
                    setUserErr('');
                    setPassErr('');
                    console.log(res)
                    localStorage.setItem('access_token', JSON.stringify(res.data.records.user.access_token))

                }
            })
            .catch((err) => {
                //error
            });
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    };


    return (
        <div>
            <HeaderAuth />
            <section className="aut-wrap">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-5 hide-on-mobile">
                            <img src={imgSignIn} className="img-fluid" alt="" />
                        </div>

                        <div className="col-md-5 offset-md-2">
                            <div className="card auth-card">

                                <div className="card-body">
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <h3 className="text-center mb-4">Sign in to School Room Help</h3>
                                    
                                    <div className="form-group mb-3 input-icon">
                                        <input type="email" name="email" className="form-control" pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+" placeholder="Email" required />
                                        <div className="invalid-feedback">Enter a valid email address</div>
                                        <span>
                                            <i className="ri-mail-line"></i>
                                        </span>
                                    </div>
                                    <div className="form-group mb-3 input-icon">
                                        <input type="password" name="password" className="form-control" placeholder="Password" required />
                                        <div className="invalid-feedback">Enter password</div>
                                        <div className="error">{pass_err && pass_err}</div>
                                        <span>
                                            <i className="ri-lock-password-line"></i>
                                        </span>
                                    </div>
                                    <div className="form-group mb-3">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3">
                                        <div className="error">{user_err}</div>
                                        <div className="error mb-1">{verify_err} {verify_err ? <Link to="/resend-email-verification">Resend Email Verification</Link> : ''}</div>
                                        <div className="error mb-1">{verified} </div>

                                        <button className="button button-primary button-round button-block">Login</button>
                                    </div>
                                    <div className="form-group mb-3">
                                        <Link to="/signup" className="button button-secondary button-round button-block">Register Now</Link>
                                    </div>
                                    <div className="form-group mb-3">
                                        <div className="form-check text-center">
                                            <label className="form-check-label">
                                                <Link to="/forgot">Forgot Password?</Link>
                                            </label>
                                            
                                        </div>
                                    </div>
                                    <div className="form-group mb-3">
                                        
                                        <FacebookLogin
                                            appId={config.facebook_app_id}
                                            
                                            fields="first_name,last_name,name,email,picture"
                                            cssClass="button button-fb button-icon button-round button-block"
                                            icon="fab fa-facebook-f"
                                            onClick={componentClicked}
                                            callback={responseFacebook} />
                                    </div>
                                    <div className="form-group mb-3">
                                        
                                        <GoogleLogin
                                            clientId={config.google_client_id}
                                            buttonText="Signup with Gmail"
                                            className="button button-outline-gray button-icon button-round button-block"
                                            onSuccess={responseGoogle}
                                            onFailure={responseGoogle}
                                            cookiePolicy={'single_host_origin'}
                                          />
                                    </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default SignIn;