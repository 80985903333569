import React from 'react';
import ReactDOM from 'react-dom';

import {
  BrowserRouter,
  Routes,
  Route,Navigate
} from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './assets/css/auth.css';
import SignIn from './components/auth/signin';
import SignUp from './components/auth/signup';
import Welcome from './components/auth/welcome';
import My404Component from './components/errors/my404'
import ForgotPassword from './components/auth/forgotpassword';
import EmailVerification from './components/auth/emailVerification';
import ResendEmailVerification from './components/auth/resendEmailVerification';

import Blank from './components/pages/blank';
import CheckInbox from './components/auth/checkinbox';

import Introduction from './components/profileprocess/introduction';
import CreateProfile from './components/profileprocess/createprofile';

const isAuthenticated=!!localStorage.getItem('access_token');

ReactDOM.render(
  <BrowserRouter>
    <Routes >
      <Route path="/" element={<Blank title="Home" />} />
      <Route path="/welcome" element={<Welcome />} />
      <Route path="/email-verification" element={<EmailVerification />} />
      <Route path="/resend-email-verification" element={<ResendEmailVerification />} />

      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />}/>

      <Route path="/forgot" element={<ForgotPassword />} />
      <Route path="/checkinbox" element={<CheckInbox />} />
      <Route path="/about" element={<Blank title="About Us" />} />
      <Route path="/contact" element={<Blank title="Contact Us" />} />
      <Route path="/blog" element={<Blank title="Blog" />} />
      <Route path="/faqs" element={<Blank title="FAQs" />} />

      <Route path="*" element={<My404Component/>} />

      
      <Route path="/introduction" element={<Introduction />} />
      <Route path="/create-profile" element={<CreateProfile />} />

    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
