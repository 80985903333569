import HeaderAuth from "../../header/home/HeaderAuth";
function my404() {
    

    return (
        <div>
            <HeaderAuth />
            
            <div className="text-center mt-5"><h3>404! Page Not Found</h3></div>

        </div>
    )
}

export default my404;