import { Link } from "react-router-dom";
import HeaderAuth from "../../header/home/HeaderAuth";
import imgCheckBox from "../../assets/images/auth/check-inbox.svg";

function CheckInbox() {
    return(
        <div>
            <HeaderAuth />
            <section class="aut-wrap">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 hide-on-mobile">
                    <img src={imgCheckBox} class="img-fluid" alt="" />
                </div>
                <div class="col-md-5 offset-md-1">
                    <div class="card auth-card">
                        <div class="card-body">
                            <h3 class="text-center mb-1">Check your inbox</h3>
                            
                            <p class="text-center mt-0 mb-3">We’ve sent a reset link to your inbox.<br />If it doesn’t show up soon, check your spam folder.</p>
                            
                            
                            <div class="form-group mb-3">
                                <div class="form-check text-center">
                                    <label class="form-check-label">
                                        <a href="#">Resend Email</a>
                                    </label>
                                </div>
                            </div>
                           
                            <div class="form-group mb-2">
                                <Link to="/signin" class="button button-primary button-round button-block">Back to Login</Link>
                            </div>
                           
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </div>
    )
}
export default CheckInbox;