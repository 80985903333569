import getStarted from '../../assets/images/get_started.svg';
import introduction from '../../assets/images/profile/introduction.png';
import { Link } from "react-router-dom";
import PageHead from "../../header/home/PageHead";
import HeaderProfileProcess from "../../header/home/HeaderProfileProcess";


function Introduction() {
    return (
        <div>
            <HeaderProfileProcess />
            <div className="pp-wrap">
                <PageHead />
                <section className="page-title">
                    <div className="item-left">
                        <div className="title">
                            <img src={getStarted} className="img-fluid" alt="" />
                            <h1>How To get started?</h1>
                        </div>
                    </div>
                    <div className="item-right">
                        <ul className="wizard">
                            <li>
                                <a href="#" className="active">
                                    <span className="icon">
                                        <i className="fas fa-check-circle"></i>
                                    </span>
                                    <span className="label">Create Profile</span>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="completed">
                                    <span className="icon">
                                        <i className="fas fa-exclamation-circle"></i>
                                    </span>
                                    <span className="label">Introduction</span>
                                </a>
                            </li>

                            <li>
                                <a href="#">
                                    <span className="icon">
                                        <i className="fas fa-exclamation-circle"></i>
                                    </span>
                                    <span className="label">Completed</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
            <div className="container">
                <div className="card">
                    <div className="pp-inner">
                        <div className="row align-items-center">
                            <div className="col-md-5 offset-md-1">
                                <div id="carouselIntroduction" class="carousel slide carousel-dark slide" data-bs-ride="carousel">
                                    
                                    <div class="carousel-inner">
                                        <div class="carousel-item active">
                                            <div className="intro-text">
                                                <h2><strong>Students Learning Leaderboard</strong></h2>
                                                <p>Distributed inventory also helps you stay competitive by offering
                                                    two-day shipping to your customers. When an order is placed,
                                                    algorithm automatically selects the warehouse and carrier that will
                                                    give you the quickest turnaround at the best price. </p>
                                                <div className="mt-3">
                                                    <button className="button button-round button-light">Skip</button>
                                                    <button className="button button-round button-primary ms-2"  data-bs-target="#carouselIntroduction" data-bs-slide-to="1">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div className="intro-text">
                                                <h2><strong>Students Learning Leaderboard</strong></h2>
                                                <p>Distributed inventory also helps you stay competitive by offering
                                                    two-day shipping to your customers. When an order is placed,
                                                    algorithm automatically selects the warehouse and carrier that will
                                                    give you the quickest turnaround at the best price. </p>
                                                <div className="mt-3">
                                                    <button className="button button-round button-light">Skip</button>
                                                    <button className="button button-round button-primary ms-2"  data-bs-target="#carouselIntroduction" data-bs-slide-to="2">Next</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="carousel-item">
                                            <div className="intro-text">
                                                <h2><strong>Students Learning Leaderboard</strong></h2>
                                                <p>Distributed inventory also helps you stay competitive by offering
                                                    two-day shipping to your customers. When an order is placed,
                                                    algorithm automatically selects the warehouse and carrier that will
                                                    give you the quickest turnaround at the best price. </p>
                                                <div className="mt-3">
                                                    <button className="button button-round button-light">Skip</button>
                                                    <Link to="/create-profile" className="button button-round button-primary ms-2">Finish</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselIntroduction" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselIntroduction" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselIntroduction" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                </div>



                            </div>
                            <div className="col-md-4 offset-md-1">
                                <img src={introduction} className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction;