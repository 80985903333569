import imgForgot from '../../assets/images/auth/forgot-password.svg';
import { Link,useNavigate } from 'react-router-dom';
import HeaderAuth from "../../header/home/HeaderAuth";
import { Form } from 'react-bootstrap';
import React, { useState,useEffect } from 'react';
import axios from './../../axios';

function ResendEmailVerification()
{
    const isAuthenticated=!!localStorage.getItem('access_token');

    const [validated, setValidated] = useState(false);
    const [email_err, setEmailErr] = useState('');
    let navigate = useNavigate();

    useEffect(() => {

        if(isAuthenticated){
            navigate('/');
            console.log('Logged In');
        }
        else{
            console.log('Not Logged In');
        }
        

    });
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else{
            const formData = {
                email : event.target.email.value,
            };

            axios
            .post("/resend-verification-email",formData)
            .then((res) => {
                setEmailErr(res.data._metadata.message);
                event.preventDefault();
        event.stopPropagation();
            })
            .catch((err)=>{
                //error
            })
        }
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
    }
    return(
        <div>
            <HeaderAuth />
            <section className="aut-wrap">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-6 hide-on-mobile">
                    <img src={imgForgot} className="img-fluid" alt="" />
                </div>
                <div className="col-md-5 offset-md-1">
                    <div className="card auth-card">
                        <div className="card-body">
                            <h3 className="text-center mb-1">Resend Email Verification</h3>
                            
                            <p className="text-center mt-0 mb-5">You can resend again your email verification. Input Email .</p>           
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            
                                <div className="form-group mb-3 input-icon">
                                    <input type="email" name="email" className="form-control" pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])+" placeholder="Email" required/>
                                    <div className="invalid-feedback">Email is Required</div>
                                    <span>
                                        <i class="ri-mail-line"></i>
                                    </span>
                                </div>
                                <div className="error mb-1">{email_err}</div>
                                <div className="form-group mb-3">
                                    <button className="button button-primary button-round button-block">Resend Verify Email</button>
                                </div>
                            </Form>
                            <div className="form-group mb-3">
                                <div className="form-check text-center">
                                    <label className="form-check-label">
                                         Already have an account? &nbsp; 
                                        <Link to="/signin">Return to Signin</Link>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </div>
    );
}

export default ResendEmailVerification;