import { Tooltip } from "bootstrap";
import HeaderProfileProcess from "../../header/home/HeaderProfileProcess";
import PageHead from "../../header/home/PageHead";


var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
              return new Tooltip(tooltipTriggerEl)
        })

function CreateProfile()
{
    
    
    return(
        
        <div>
            <HeaderProfileProcess />
            <div className="pp-wrap">
                <PageHead />
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="card">
                                <div className="card-body p-4 form-style1">
                                    <div className="title-underline">
                                        <i class="ri-user-add-line me-2"></i>  Create My Profile
                                    </div>
                                    <div className="mt-2 mb-4">
                                    
                                        <div className="photo-upload">
                                            <label for="photo-upload">
                                            <i class="ri-image-add-line"></i>
                                                <span>Upload Photo <i class="ri-information-line" data-bs-toggle="tooltip" data-bs-placement="top" title="Only .jpg, .jpeg allowed"></i></span>
                                            </label>
                                            <input type="file" id="photo-upload" />   
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="form-label">Select Country</label>
                                            <select class="form-select">
                                                <option>Select Country</option>
                                                <option>Pakistan</option>
                                                <option>United States</option>
                                                <option>Germany</option>
                                                <option>Canada</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <labe className="form-label">State/Province <i className="ri-information-line"></i></labe>
                                            <input type="text" className="form-control" placeholder="E.g NY" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="form-label">City</label>
                                            <input type="text" className="form-control" placeholder="City" />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <labe className="form-label">Zip <i className="ri-information-line"></i></labe>
                                            <input type="text" className="form-control" placeholder="E.g 54000" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="form-label">Email</label>
                                            <input type="email" className="form-control" placeholder="E.g John@gmail.com" />
                                        </div>
                                        <div className="col-md-6 form-group mb-3">
                                            <labe className="form-label">Contact Number <i className="ri-information-line"></i></labe>
                                            <input type="text" className="form-control" placeholder="212 8490 5690" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 form-group mb-3">
                                            <label className="form-label">DOB</label>
                                            <input type="text" className="form-control" placeholder="12/02/1980" />
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-md-4 form-group mb-3">
                                                    <label className="form-label">Gender</label>
                                                    <select class="form-select">
                                                        <option>Male</option>
                                                        <option>Female</option>
                                                        
                                                    </select>
                                                </div>
                                                <div className="col-md-8 form-group mb-3">
                                                    <label className="form-label">Timezone</label>
                                                    <select class="form-select">
                                                        <option>(UTC/GMT +05:00) Asia/Karachi</option>
                                                        <option>(UTC/GMT +05:00) Asia/Karachi</option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 form-group mb-3">
                                            <label className="form-label">Address</label>
                                            <input type="text" className="form-control" placeholder="E.g TX, USA" />
                                        </div>
                                        
                                    </div>
                                   <div className="text-end">
                                       <button className="button button-round button-primary">Create My Profile</button>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateProfile;