import {Link,NavLink}  from 'react-router-dom';
import logo from '../../assets/images/logo.png';


function HeaderAuth() {

    
    return(
        
        <div>
        <div className="topbar">
        <div className="container-fluid">
            <div className="row align-items-center">
                <div className="col-md-4">
                    <ul className="top-left">
                        <li>
                            <a href="#">
                                <i className="far fa-envelope me-2"></i>
                                <span>hello@schoolroomhelp.com</span>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="fab fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="fab fa-linkedin-in"></i>
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <i className="fab fa-google-plus-g"></i>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-md-8">
                    <ul className="top-right">
                        <li>
                            <span>How you works School Room Help?</span>
                            <Link to="/signin" className="button button-success button-round button-icon">
                            <i class="ri-login-box-line"></i>
                                Sign In
                            </Link>
                        </li>
                        <li>
                            <span>What you takes school room Help?</span>

                            <Link to="/signup" className="button button-danger button-round button-icon">
                            <i class="ri-user-add-line"></i>
                                Sign Up
                            </Link>
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <header>
        <nav className="navbar navbar-expand-md">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={logo} alt="" />
                </a>
                <button className="navbar-toggler me-2" id="nav-icon1" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/">
                                {/* <img src={icHome} alt="" /> */}
                                <i class="ri-home-4-line"></i>
                                <span>Home</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/about">
                                {/* <img src={icUsers} alt="" /> */}
                                <i class="ri-group-line"></i>
                                <span>About</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/blog">
                                {/* <img src={icBlog} alt="" /> */}
                                <i class="ri-article-line"></i>
                                <span>Blog</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/faqs">
                                {/* <img src={icFaq} alt="" /> */}
                                <i class="ri-questionnaire-line"></i>
                                <span>FAQs</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact">
                                {/* <img src={icContact} alt="" /> */}
                                <i class="ri-phone-line"></i>
                                <span>Contact Us</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    </div>
    );
}

export default HeaderAuth;