import { Link } from 'react-router-dom';
import imgWelcome from '../../assets/images/auth/register-welcome.svg';
import HeaderAuth from "../../header/home/HeaderAuth";
function EmailVerification()
{
    
    
    return(
        <div>
            <HeaderAuth />
            <section className="aut-wrap">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-7 hide-on-mobile">
                    <img src={imgWelcome} className="img-fluid" alt="" />
                </div>
                <div className="col-md-5">
                    <div className="card auth-card">
                        <div className="card-body">
                            <h2 className="text-center mb-2">Email Verification</h2>
                           
                            <p className="text-center mb-5">Your email has been successfully verified</p>
                           
                            <div className="form-group mb-3">
                                <Link to="/signin" className="button button-primary button-round button-block">Back to Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        </div>
    );
}

export default EmailVerification;